@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.bannerItem {
  background-color: $color-dark-5;
  padding: 32px;
  border-radius: 24px;
  margin-bottom: 8px;

  .innerBannerContainer {
    display: flex;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 48px;
  }

  &.edit {
    padding: 16px;

    &:last-child {
      margin-bottom: 32px;
    }
  }

  .productLinkForm {
    margin-bottom: 16px;
    min-height: 40px;

    &:last-child {
      margin-bottom: 0;
    }
    > :first-child {
      justify-content: center;
    }
  }

  .labelContainer {
    flex: 1 0 auto;
  }

  .label {
    @extend .bodyS;
    color: $color-dark-75;
    background: $color-white-100;
    border-radius: 8px;
    padding: 10px 18px;

    height: 40px;
    width: 100%;
    max-width: 355px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .previewContainer {
    position: relative;
    width: 96px;
    height: 96px;
    background: $color-dark-10;
    border-radius: 8px;
    margin: 0 16px;
    overflow: hidden;
    cursor: pointer;

    display: flex;
    align-items: center;
    flex: 0 0 auto;

    &:hover {
      .previewEnlarge {
        opacity: 1;
      }
    }

    .preview {
      width: 100%;
    }

    .previewEnlarge {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: 0.2s ease all;
      background-color: $color-dark-50;

      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 24px;
        height: 24px;
        fill: $color-white-100;
      }
    }
  }
  .remove {
    flex: 0 0 auto;
  }

  .urlOverride {
    display: flex;
    align-items: center;

    .urlOverrideToggle {
      flex: 0 0 auto;
      margin-left: 16px;
    }
  }
}

.dialogContainer {
  .dialogContent {
    margin-bottom: 32px;

    .icon {
      display: block;
      width: 32px;
      height: 32px;
      fill: $color-red-100;
      margin-bottom: 16px;
    }

    .title {
      @extend .head3;
      color: $color-dark-100;
      margin-bottom: 16px;
    }

    .subtitle {
      @extend .bodyL;
      color: $color-dark-75;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    > :not(:last-child) {
      margin-right: 8px;
    }
  }
}
