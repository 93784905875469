@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.error {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.fieldsContainer {
  display: flex;

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.totalBudgetContainer {
  display: flex;
  align-items: center;

  .totalBudgetInput {
    flex: 1 0 auto;
    margin-right: 8px;
  }
}
