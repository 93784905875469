@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.visible {
  position: fixed;
  width: 100%;
  background: $color-white-100;
  border-bottom: 1px solid $color-dark-10;
  z-index: 100;
}

.marginDummy {
  height: 56px;
}

.container {
  padding-top: 8px;
  padding-bottom: 8px;

  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  .logoLink {
    display: flex;
    align-items: center;
  }

  > a:not(last-child) {
    margin-right: 24px;
  }

  .logo {
    height: 32px;
  }
}

.userMenu {
  display: flex;
  align-items: center;

  .userMenuButton {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 4px 4px 4px 8px;

    .menuIcon {
      width: 16px;
      height: 16px;
      fill: $color-dark-100;
      margin-right: 8px;
    }
    .userName {
      @extend .buttonS;
      letter-spacing: -0.1px;
      color: $color-dark-100;
      margin-right: 8px;
    }
    .userIcon {
      width: 32px;
      height: 32px;
      border: 2px solid var($theme-primary-100);
      border-radius: 32px;
    }
  }

  button {
    margin-right: 8px;
  }
}
