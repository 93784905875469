@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.base {
  width: 100%;
  min-height: 506px;
  background: $color-dark-5;
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &:not(.disabled) {
    &:hover {
      background: $color-white-100;
      border: 1px solid $color-dark-5;
      box-shadow: 0 8px 64px $color-dark-25;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.selected {
    background: $color-white-100;
    border: 1px solid var($theme-primary-100);
    box-shadow: 0 8px 64px $color-dark-25;
  }

  .ribbon {
    @extend .head4;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 5%;
    left: 38%;
    width: 100%;
    padding: 6px 16px;
    transform: rotate(45deg);
    color: $color-white-100;
    background: $color-notice-100;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tag {
    @extend .head4;
    display: flex;
    align-items: center;
    text-align: center;
    color: $color-dark-100;
    background: $color-yellow-100;
    height: 40px;
    padding: 8px 24px;
    border-radius: 32px;
    margin-bottom: 24px;
  }

  .title {
    @extend .head3;
    text-align: center;
    color: $color-dark-100;
    margin-bottom: 16px;
  }

  .subtitle {
    @extend .bodyS;
    text-align: center;
    color: $color-dark-75;
    margin-bottom: 24px;
  }

  .iconContainer {
    width: 240px;
    height: 240px;

    > svg {
      width: 240px;
      height: 240px;
    }
  }
}
