@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.error {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 8px;

  @extend .head4;
  color: $color-dark-100;

  .itemTitle {
    margin-left: 8px;
  }
}
