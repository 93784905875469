@import 'sass/index.scss';
@import 'sass/_colors.scss';
@import 'sass/_typography.scss';

.auth {
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var($theme-primary-100);
  background-image: var($theme-background);
  background-size: 100% 100%;
  -o-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;

  .wrap {
    width: 100%;
    max-width: 502px;
  }

  .form {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 32px;
    width: 100%;
    max-width: 502px;
    background: $color-white-100;
    border: 1px solid $color-dark-5;
    box-shadow: 0 8px 64px $color-dark-25;
    border-radius: 24px;

    .title {
      @extend .head2;
      color: $color-dark-100;
    }

    .subtitle {
      @extend .bodyL;
      color: $color-dark-75;
    }
  }
}
