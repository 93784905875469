@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-white-75;
  backdrop-filter: blur(4px);
  z-index: 100;
}

.form {
  position: fixed;
  top: 86px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  width: 100%;
  max-width: 502px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 32px;

  background: $color-white-100;
  border: 1px solid $color-dark-5;
  box-shadow: 0 8px 64px $color-dark-25;
  border-radius: 24px;

  .title {
    @extend .head2;
    color: $color-dark-100;
    margin-bottom: 4px;
  }

  .subtitle {
    @extend .bodyL;
    color: $color-dark-75;
    margin-bottom: 24px;
  }
}
