@import 'sass/_colors.scss';

.ant-switch {
  height: 28px;

  .ant-switch-handle {
    height: 24px;
    width: 24px;

    &:before {
      border-radius: 24px;
    }
  }

  background-color: $color-dark-10;

  &:hover {
    background-color: var($theme-primary-25);
  }

  &:focus {
    box-shadow: none;
  }
}

.ant-switch-checked {
  background-color: var($theme-primary-100);

  .ant-switch-handle {
    left: calc(100% - 24px - 2px);
  }

  &:hover {
    background: linear-gradient(0deg, $color-dark-25, $color-dark-25), var($theme-primary-100);
  }
}
