@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.sizesContainer {
  display: flex;
  align-items: center;

  > :nth-child(1) {
    margin-right: 8px;
  }

  > :nth-child(2) {
    margin-left: 8px;
  }
}

.sizePresets {
  margin-bottom: 8px;
}


.sizeOverrideToggle {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    .icon {
      fill: var($theme-primary-75);
    }
  }

  .icon {
    width: 12px;
    height: 12px;
    fill: var($theme-primary-100);
    transition: all 0.2s;
    margin-left: 4px;
    flex: 0 0 auto;
    transform: rotate(-90deg);

    &.expanded {
      transform: rotate(0deg);
    }
  }
}
