@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.expandContainer {
  display: flex;
  justify-content: flex-end;
}

.error {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.fileUploadContainer {
  padding-top: 16px;
  margin-bottom: 32px;
}

.dialogContainer {
  .dialogContent {
    margin-bottom: 32px;

    .icon {
      display: block;
      width: 32px;
      height: 32px;
      fill: $color-red-100;
      margin-bottom: 16px;
    }

    .title {
      @extend .head3;
      color: $color-dark-100;
      margin-bottom: 16px;
    }

    .subtitle {
      @extend .bodyL;
      color: $color-dark-75;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    > :not(:last-child) {
      margin-right: 8px;
    }
  }
}