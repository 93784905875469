@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.container {
  display: flex;

  background: $color-white-100;
  border: 1px solid $color-dark-10;
  border-radius: 24px;
  overflow: hidden;

  height: auto;
  max-height: 482px;
  margin-bottom: 16px;

  .selectableItem,
  .selectedItem {
    padding: 8px 16px;
  }
  .createItem {
    padding: 4px 16px;
  }

  .allItems {
    width: 50%;
    border-right: 1px solid $color-dark-10;
    &.withEnter {
      width: 100%;
      border: none;
    }
  }

  .selectedItems {
    width: 50%;
    background-color: $color-white-100;
  }

  .header {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 1px 0 $color-dark-10;
    padding: 16px;

    &.withSearch {
      min-height: 144px;
    }

    &.column {
      display: block;
    }

    .tabContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 1px 0 $color-dark-10;
      padding-bottom: 8px;
      margin-bottom: 16px;

      button {
        margin-right: 8px;
      }
    }

    .title {
      @extend .bodyS;
      color: $color-dark-100;
      margin-right: 32px;
    }

    .actions {
      > :not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .body {
    padding: 16px 0;
    height: 336px;
    overflow: auto;
  }
}

.selectableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  @extend .bodyS;
  color: $color-dark-75;

  .add {
    @extend .button;
    background: var($theme-primary-100);
    margin-left: 16px;

    .icon {
      @extend .icon;
      fill: $color-white-100;
    }

    &:hover {
      background: var($theme-primary-75);
    }
  }

  &.bold {
    font-weight: bold;
  }

  .checkbox {
    margin-right: 16px;
  }

  .thumbCheckbox {
    margin-right: 8px;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .link {
    @extend .name;
    margin-left: 8px;
    width: 100%;
  }
}

.button {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 4px;
  transition: 0.4s ease all;
  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
}

.selectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  @extend .bodyS;
  color: $color-dark-100;

  .remove {
    @extend .button;
    background: $color-dark-5;
    margin-left: 16px;

    .icon {
      @extend .icon;
      fill: $color-dark-100;
    }

    &:hover {
      background: $color-dark-10;
    }
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .link {
    @extend .name;
    margin-left: 8px;
    width: 100%;
  }
}

.createItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;

  min-height: 40px;

  .input {
    width: 100%;
    margin-right: 8px;
  }

  .actions {
    display: flex;

    .save {
      @extend .button;
      background: var($theme-primary-100);
      margin-right: 8px;
      .icon {
        @extend .icon;
        fill: $color-white-100;
      }
    }
    .remove {
      @extend .button;
      background: $color-dark-5;
      .icon {
        @extend .icon;
        fill: $color-dark-100;
      }
    }
  }
}

.thumbnailContainer {
  width: 48px;
  height: 48px;
  background: $color-dark-10;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex: 0 0 48px;
  overflow: hidden;

  .thumbnail {
    width: 100%;
  }
}

.addContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.loadMoreContainer {
  padding: 8px 16px;
  text-align: center;
}

.invalidItems {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}
