@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.popup {
  padding: 24px;
  width: 256px;
  background: $color-white-100;
  border: 1px solid $color-dark-5;
  box-shadow: 0 8px 64px $color-dark-25;
  border-radius: 24px;
  position: absolute;
  right: 0;
  top: 50px;
  margin: 0 auto;
  z-index: 222;
}

.popupItem {
  @extend .buttonS;
  height: 40px;
  padding: 12px 16px;
  color: $color-dark-100;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    background: var($theme-primary-10);
    border-radius: 8px;
    color: var($theme-primary-100);
  }

  &.addon:hover::after {
    content: '';
    position: absolute;
    right: 12px;
    bottom: 12px;
    width: 16px;
    height: 16px;
    border: 5px solid var($theme-primary-100);
    background: $color-white-100;
    border-radius: 50%;
  }
  &.red {
    &:hover {
      background: $color-red-10;
      border-radius: 8px;
      color: $color-red-100;
    }
  }
}
