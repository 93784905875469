@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-white-75;
  backdrop-filter: blur(4px);
  z-index: 100;
}

.dialog {
  position: fixed;
  top: 86px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  width: 100%;
  max-width: 502px;

  background: $color-white-100;
  border: 1px solid $color-dark-5;
  box-shadow: 0 8px 64px $color-dark-25;
  border-radius: 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 0 $color-dark-10;
    padding: 20px;
    .title {
      @extend .head4;
      font-size: 16px;
      line-height: 22px;
      color: $color-dark-100;
      margin-right: 24px;
    }
  }

  .body {
    max-height: 448px;
    padding: 32px;
    overflow: auto;
  }
}
