.login {
  width: 100%;

  .inputs {
    width: 100%;
    padding: 20px 0;

    > div {
      margin: 4px 0;
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    > :first-child {
      margin-right: 8px;
    }
    .button {
      flex: 1 1 50%;
    }
  }
}
