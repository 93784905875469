@import 'sass/_colors.scss';

.headerSection,
.col,
.padding24 {
  display: flex;
}

.headerSection {
  padding-top: 48px;
  padding-bottom: 24px;
}

.metricItem {
  padding: 1em 1em 1.5em 1em;
  text-align: center;
  box-shadow: 0 8px 64px rgba(0, 27, 58, 0.25);
  border-radius: 24px;
  h2 {
    color: $color-dark-50;
  }
  h1 {
    margin-bottom: 0;
  }
  &:hover {
    outline: 2px solid #479CFF;
  }
}

.analyticsTable {
  width: 100%;
}
.lastTh {
  text-align: right !important;
}