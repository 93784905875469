@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.selectContainer {
  width: 100%;
}

.ant-select-selection-search{
  margin-top: 15px;
}

.ant-select {
  width: 100%;
  &.small .ant-select-selector {
    padding: 6px 8px !important;
    height: 32px !important;
    .ant-select-selection-item {
      line-height: 20px !important;
    }
  }
  .ant-select-selector {
    @extend .bodyS;
    // margin-top: 15px;
    padding: 14px 32px 6px 16px !important;
    height: 48px !important;
    border-radius: 8px !important;
    border-color: $color-dark-10 !important;
    color: $color-dark-100;
    box-shadow: none !important;

    &::placeholder {
      color: $color-dark-50 !important;
    }

    &:hover {
      border-color: $color-dark-50 !important;
      box-shadow: none;
      outline: none;
    }
  }

  .ant-select-clear {
    right: 36px;
    .clear {
      width: 16px;
      height: 16px;
      fill: $color-dark-50;
    }
  }

  &.small {
    .ant-select-arrow {
      top: 53%;
      right: 10px;
      width: 12px;
      height: 12px;
      .arrow {
        width: 12px;
        height: 12px;
        fill: $color-dark-50;
      }
    }
  }
  .ant-select-arrow {
    top: 50%;
    right: 16px;
    width: 16px;
    height: 16px;
  }
  .arrow {
    width: 16px;
    height: 16px;
    fill: $color-dark-50;
  }
}

.ant-select-focused {
  .ant-select-selector {
    border-color: var($theme-primary-100) !important;
  }
  .ant-select-arrow > .arrow {
    fill: $color-dark-100;
  }
  .ant-select-clear > .clear {
    fill: $color-dark-100;
  }
}

.select-error {
  .ant-select-selector {
    border-color: $color-red-100 !important;
  }
}
