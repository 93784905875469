@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.textarea {
  @extend .bodyS;
  padding: 16px 16px 6px;
  height: 48px;

  border-radius: 8px;
  border-color: $color-dark-10;
  color: $color-dark-100;
  resize: none;

  &::placeholder {
    color: $color-dark-50;
  }

  &:hover {
    border-color: $color-dark-50;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border-color: var($theme-primary-100);
    box-shadow: none;
    outline: none;
  }

  &.error {
    border-color: $color-red-100;
  }
}
