@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border: 1px dashed $color-dark-25;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.2s ease all;

  &:hover {
    background-color: $color-dark-5;
  }
  &.active {
    background-color: var($theme-primary-10);
  }

  .placeholder {
    @extend .head4;
    text-align: center;
    color: $color-dark-100;
  }

  .separator {
    @extend .caption;
    text-align: center;
    color: $color-dark-25;
    margin: 8px 0;
  }
}
