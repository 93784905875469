@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.inputContainer {
  width: 100%;
}
.ant-input,
.ant-input-number {
  @extend .bodyS;
  padding: 16px 16px 6px;
  height: 48px;
  border-radius: 8px;
  border-color: $color-dark-10;
  color: $color-dark-100;

  &::placeholder {
    color: $color-dark-50;
  }

  &:hover {
    border-color: $color-dark-50;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border-color: var($theme-primary-100);
    box-shadow: none;
    outline: none;
    z-index: 1;
  }
}

.small-input {
  height: 32px;
  width: 92px;
}

.no-label-input-wrapper {

  span,
  input,
  .small-input {
    height: 32px;
  }

  input {
    padding: 6px 8px 6px 8px;
  }

  .error {
    .ant-input-group-addon,
    .ant-input {
      border-color: $color-red-100;
    }
  }
}

.addon-before .ant-input-group-addon {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: $color-dark-50;
  background: $color-dark-5;
  padding: 0 8px;
}

.addon-before .label {
  left: 41px;
}

.addon-after .ant-input-group-addon {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: $color-dark-50;
  background: $color-dark-5;
  padding: 0 8px;
}

.prefix-icon-input .label {
  left: 40px;
}

.ant-input-affix-wrapper {
  padding: 0;
  border-radius: 8px;
  position: static;
  height: 48px;

  input.ant-input {
    padding: 16px 16px 6px 0;
    height: auto;
  }

  .ant-input-prefix {
    padding-left: 16px;
    padding-right: 8px;
    margin-right: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.ant-input-affix-wrapper:hover, &:hover {
    border-color: $color-dark-50;
    box-shadow: none;
    outline: none;
  }

  &.ant-input-affix-wrapper-focused, &:focus {
    border-color: var($theme-primary-100) !important;
    box-shadow: none;
    outline: none;
    z-index: 1;
  }
}

.ant-input-password {
  input.ant-input {
    padding: 16px 16px 6px;
  }

  &:hover {
    border-color: $color-dark-50;
    box-shadow: none;
    outline: none;
  }

  &:focus-within {
    border-color: var($theme-primary-100);
    box-shadow: none;
    outline: none;
    z-index: 1;
  }
}

.ant-input-password-icon {
  margin-right: 10px;
}

.error-text {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
