@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.dialogContainer {
    .dialogContent {
      margin-bottom: 10px;
  
      .icon {
        display: block;
        width: 32px;
        height: 32px;
        fill: $color-red-100;
        margin-bottom: 16px;
      }
  
      .title {
        @extend .head3;
        color: $color-dark-100;
        margin-bottom: 16px;
      }
  
      .subtitle {
        @extend .bodyL;
        color: $color-dark-75;
      }

      .spinner {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-top: 50px;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      > :not(:last-child) {
        margin-right: 8px;
      }
    }
  }