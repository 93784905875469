@import 'sass/_colors.scss';

.visible {
  position: fixed;
  width: 100%;
  background: $color-white-100;
  border-top: 1px solid $color-dark-10;
  z-index: 100;
  bottom: 0;
}

.marginDummy {
  height: 64px;
}

.container {
  padding-top: 8px;
  padding-bottom: 8px;
}
