@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.email {
  margin-bottom: 8px;
}

.emailDescription {
  @extend .bodyS;
  color: $color-dark-75;
  margin-bottom: 24px;
}

.password {
  margin-bottom: 8px;
}

.passwordRepeat {
  margin-bottom: 8px;
}

.phone {
  margin-bottom: 24px;
}

.terms {
  @extend .bodyS;
  color: $color-dark-50;

  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .checkbox {
    margin-right: 16px;
  }

  .link {
    color: var($theme-primary-100);
  }
}

.submit {
  width: 100%;
}
