@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.line-chart-wrapper {
  height: 350px;
  background-color: $color-dark-5;
  padding: 48px 40px 20px 0;
  border-radius: 16px;

  .title {
    @extend .head3;
    padding-left: 48px;
    margin-bottom: 32px;
  }

  .xAxis,
  .yAxis {
    @extend .caption;
    color: $color-dark-100;
  }

  .custom-tooltip {
    background-color: $color-white-100;
    padding: 12px 16px;
    box-shadow: 0 8px 16px $color-dark-10;
    border-radius: 8px;
    border: 1px solid $color-dark-10;
    display: flex;
    flex-direction: column;

    .value {
      @extend .caption;
      color: $color-dark-50;
      margin: 2px 0;
    }

    .date {
      @extend .bodyS;
      font-weight: 700;
      color: $color-dark-100;
    }
  }
}
