@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.ant-btn {
  @extend .buttonS;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  transition-duration: 0.4s;
  border: 0;
  color: $color-white-100 !important;
  background-color: var($theme-primary-100) !important;

  &:hover,
  &:active {
    background-color: var($theme-primary-75) !important;
    color: $color-white-100 !important;
  }

  &:focus {
    background-color: var($theme-primary-100);
    color: $color-white-100;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    fill: $color-white-100;
  }

  span {
    margin-top: 2px;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    opacity: 0.5;
    color: $color-white-100 !important;
    background-color: var($theme-primary-100) !important;
  }
}

.ant-btn-primary {
  color: var($theme-primary-100) !important;
  background-color: var($theme-primary-10) !important;
  text-shadow: none;
  box-shadow: none;

  &:hover,
  &:active {
    color: var($theme-primary-100) !important;
    background-color: var($theme-primary-25) !important;
  }

  &:focus {
    background-color: var($theme-primary-10);
    color: var($theme-primary-100);
  }

  svg {
    fill: var($theme-primary-100);
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    opacity: 0.5;
    color: var($theme-primary-100) !important;
    background-color: var($theme-primary-10) !important;
  }
}

.ant-btn-ghost {
  color: $color-dark-100 !important;
  background-color: $color-dark-5 !important;
  text-shadow: none;
  box-shadow: none;

  &:hover,
  &:active {
    color: $color-dark-100 !important;
    background-color: $color-dark-10 !important;
  }

  &:focus {
    background-color: $color-dark-5;
    color: $color-dark-100;
  }

  svg {
    fill: $color-dark-100;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    opacity: 0.5;
    color: $color-dark-100 !important;
    background-color: $color-dark-5 !important;
  }
}

.ant-btn-lg {
  height: 48px;
}

.icon-button {
  width: 40px;
  padding: 0;
  justify-content: center;

  &.ant-btn-lg {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    padding: 0;
  }

  svg {
    margin-right: 0;
  }
}
