@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.content {
  margin: 48px auto 48px auto;
  max-width: 764px;

  .title {
    @extend .head2;
    color: $color-dark-100;
    margin-bottom: 48px;
  }
}

.footerContent {
  max-width: 764px;
  margin: auto;
  display: flex;
  justify-content: flex-end;

  > *:first-child {
    margin-right: 8px;
  }
}
