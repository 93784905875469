@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.base {
  margin-bottom: 40px;
}

.stepsContainer {
  display: flex;
  margin-bottom: 16px;
}

.stepContainer {
  padding-right: 25px;
  opacity: 0.5;
  &.active {
    opacity: 1;
  }

  .stepTitle {
    @extend .head4;
    color: $color-dark-100;
  }
}

.barContainer {
  display: flex;
  min-height: 32px;
}

.barStepContainer {
  display: flex;
  align-items: center;

  .barDot {
    z-index: 1;
    width: 16px;
    height: 16px;
    background: $color-dark-10;
    border: 4px solid $color-white-100;
    border-radius: 32px;
    flex: 1 0 auto;
    &.active {
      background: $color-white-100;
      border: 4px solid $color-mantis-100;
    }
  }

  .barLine {
    height: 8px;
    background: $color-dark-5;
    flex: 1 1 100%;
    margin-left: -1px;
    margin-right: -1px;
    &.active {
      background: $color-mantis-100;
    }
  }

  .finalStep {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;

    background: $color-dark-10;
    border: 4px solid $color-white-100;
    border-radius: 32px;

    svg {
      width: 16px;
      height: 16px;
      fill: $color-dark-25;
    }

    &.active {
      background: $color-white-100;
      border: 4px solid $color-mantis-100;

      svg {
        fill: $color-mantis-100;
      }
    }
  }
}
