@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.range-label {
  @extend .head4;
  color: $color-dark-100;
  margin-right: 16px;
}

.ant-picker-borderless {
  border: none;
}

.ant-picker-dropdown-range,
.ant-picker-dropdown {
  border-radius: 24px;
  border: 1px solid $color-dark-10;
  box-shadow: 0 8px 64px $color-dark-25;
  padding: 0;
  overflow: hidden;

  .ant-picker-panel-container .ant-picker-panels {
    padding: 42px 48px;

    .ant-picker-panel {
      &:first-child {
        margin-right: 30px;
      }
      &:last-child {
        margin-left: 30px;
      }
    }
  }
  .ant-picker-date-panel {
    .ant-picker-header button {
      font-weight: 700;
    }
  }
  .ant-picker-panel-container .ant-picker-panel {
    border-width: 0;
  }
  .ant-picker-header {
    border-bottom: none;
  }
  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }
  .ant-picker-date-panel .ant-picker-content th {
    font-weight: 700;
  }
  .ant-picker-cell {
    padding: 0;
    font-size: 14px;

    .ant-picker-cell-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      padding-top: 4px;
    }
    &::before {
      height: 36px;
    }
  }
  .ant-picker-content th,
  .ant-picker-content td {
    height: 36px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    border-radius: 50% !important;
    background-color: var($theme-primary-100);
    font-weight: 700;
    z-index: 100;
  }
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
    background-color: var($theme-primary-10);
    border-radius: 50% !important;
    color: var($theme-primary-100);
    font-weight: 700;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    display: none;
  }
}

.ant-picker-range,
.ant-picker {
  padding: 0;
  height: 48px;
  &:hover {
    border-color: $color-dark-50;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    border-color: var($theme-primary-100);
    box-shadow: none;
    outline: none;
  }

  &.ant-picker-focused > .ant-picker-input-active {
    border-color: var($theme-primary-100);
  }

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  .ant-picker-active-bar,
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }

  .ant-picker-input {
    border: 1px solid $color-dark-10;
    border-radius: 8px;
    padding: 15px 15px 4px 15px;

    input {
      position: relative;
      top: 0;
    }
  }
}

.ant-picker-range {
  .ant-picker-input {
    width: 122px;
  }
}

.range-picker-wrapper {
  min-width: 270px;
  display: flex;
  align-items: center;

  .range-picker {
    position: relative;
  }

  h4 {
    margin: 0;
    margin-right: 16px;
  }

  .start-wrapper,
  .end-wrapper {
    height: 10px;
    width: 10px;
    position: absolute;
    right: 111px;
    top: 4px;

    .label {
      display: none;
    }

    .label-float {
      display: block;
    }
  }

  .start-wrapper {
    left: 0;
    right: 0;
  }

  .end-wrapper {
    left: 130px;
    right: 0;
  }
}

.single-date {
  position: relative;
  width: 100%;

  &.small-input {
    width: 94px;
    .single-picker {
      height: 32px;
      .ant-picker-input {
        padding: 6px 8px;
        height: 32px;
      }
    }
  }

  .ant-picker-focused + .label-wrapper.empty {
    .label {
      display: none;
    }
  }

  .label-wrapper {
    height: 10px;
    width: 130px;
    position: absolute;
    left: 0;
    top: 0;

    .label-float {
      display: block;
    }
  }

  .single-picker {
    width: 100%;
    height: 48px;
    padding: 0;
    .ant-picker-input {
      height: 100%;
    }
    &.ant-picker-focused > .ant-picker-input {
      border-color: var($theme-primary-100);
    }
  }
}
