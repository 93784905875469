@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.passwordReset {
  width: 100%;

  .inputs {
    width: 100%;
    padding: 20px 0;

    > div {
      margin: 4px 0;
    }
  }

  .linkSent {
    text-align: center;

    svg {
      margin-bottom: 24px;
    }

    .title {
      @extend .head2;
      color: $color-dark-100;
      margin-bottom: 4px;
    }

    .subtitle {
      @extend .bodyL;
      color: $color-dark-75;
      margin-bottom: 24px;
      span {
        color: $color-dark-100;
        font-weight: 700;
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    > :first-child {
      margin-right: 8px;
    }
    .button {
      flex: 1 1 50%;
    }
    .link {
      width: 100%;
    }
  }
}
