@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.headerSection,
.padding24 {
  display: flex;
}

.headerSection {
  padding-top: 48px;
  padding-bottom: 24px;
}

.tabsCol {
  display: flex;
  align-items: center;
  flex: 0 1 40%;
}

.filterCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 60%
}

.padding24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.flexEnd {
  justify-content: flex-end;
}

.tabs {
  display: flex;

  .tab {
    @extend .head3;
    color: $color-dark-100;
    opacity: 0.25;
    margin-right: 16px;
    &.active {
      opacity: 1;
    }
  }
}

.statusSelect {
  width: 100%;
  max-width: 150px;
}

.typeSelect {
  width: 100%;
  max-width: 120px;
  margin-right: 8px;
}

.search {
  width: 100%;
  max-width: 370px;
  min-width: 220px;
  margin-left: 8px;
}

.addNewLink {
  width: 100%;
}


.download {
  margin-left: 8px;
}
