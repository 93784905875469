.campaignDuration {
  display: flex;
  .start {
    margin-right: 8px;
  }
  .end {
    margin-right: 8px;
    &.noMargin {
      margin-right: 0;
    }
  }
  .action {
    flex: 1 0 auto;
    width: 50%;
  }
  .cancelAction {
    flex: 1 0 auto;
    width: 33.33%;
  }
}
