@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.expandContainer {
  display: flex;
  justify-content: flex-end;
}

.error {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 8px;

  @extend .head4;
  color: $color-dark-100;

  .itemTitle {
    margin-left: 8px;
  }
}

.addContainer {
  display: flex;
  justify-content: flex-end;

  margin: 8px 0;
}

.searchContainer {
  margin-bottom: 16px;
}
