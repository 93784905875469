@font-face {
  font-family: 'MainFont';
  src: url('../assets/fonts/HelveticaNeue-Bold.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'MainFont';
  src: url('../assets/fonts/HelveticaNeue-Medium.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'MainFont';
  src: url('../assets/fonts/HelveticaNeue.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'MainFont';
  src: url('../assets/fonts/HelveticaNeue-Light.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
}
