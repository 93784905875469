@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.totalBudgetContainer {
  display: flex;
  align-items: center;

  .totalBudgetInput {
    flex: 1 0 auto;
    margin-right: 8px;
  }
}

.error {
  @extend .caption;
  color: $color-red-100;
}

.info {
  font-size: 12px;
  line-height: 16px;
  color: $color-dark-75;
  margin-top: 8px;
}

.icon {
  width: 16px;
  height: 16px;
  fill: $color-dark-50;
  margin-left: 4px;
  cursor: pointer;
}
