@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.faqContainer {
  margin: 48px auto 48px auto;
  max-width: 1024px;

  .title {
    @extend .head2;
    color: $color-dark-100;
  }

  .category {
    .title {
      @extend .head3;
      color: $color-dark-100;
      margin-top: 24px;
      padding-bottom: 8px;
    }
    .image {
      width: 100%;
    }
    .imageSmall {
      width: 500px;
      display: block;
      margin: 16px 0;
    }
  }
}
