@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.password {
  margin-bottom: 8px;
}

.passwordRepeat {
  margin-bottom: 24px;
}

.submit {
  width: 100%;
}
