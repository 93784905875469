@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.drawer {
  border-bottom: 1px solid $color-dark-10;

  .titleContainer {
    @extend .bodyL;
    color: $color-dark-100;
    cursor: pointer;
    padding: 16px 0;
    transition: color 0.2s;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.expanded {
      border-bottom: 1px solid $color-dark-10;
    }

    &:hover {
      color: var($theme-primary-100);

      .icon {
        fill: var($theme-primary-100);
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      fill: $color-dark-100;
      transition: all 0.2s;
      margin-left: 16px;
      flex: 0 0 auto;
      transform: rotate(-90deg);

      &.expanded {
        transform: rotate(0deg);
      }
    }
  }

  .contentContainer {
    padding: 16px 0;
    @extend .bodyS;
  }
}
