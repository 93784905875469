@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.formGroup {
  display: flex;
  min-height: 48px;
  margin-bottom: 16px;
  .labelContainer {
    width: 33.33%;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    .labelTitle {
      display: flex;
      align-items: center;
      @extend .head4;
      color: $color-dark-100;
    }
    .labelSubtitle {
      @extend .caption;
      color: $color-dark-50;
      margin-top: 8px;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    fill: $color-dark-50;
    margin-left: 4px;
    cursor: pointer;
  }
  .inputContainer {
    width: 66.66%;
    flex: 1 1 auto;
  }
}
