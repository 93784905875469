@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.error {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.fieldsContainer {
  display: flex;

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.advancedSettingsToggle {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:hover {
    .icon {
      fill:  var($theme-primary-75);
    }
  }

  .icon {
    width: 12px;
    height: 12px;
    fill: var($theme-primary-100);
    transition: all 0.2s;
    margin-left: 4px;
    flex: 0 0 auto;
    transform: rotate(-90deg);

    &.expanded {
      transform: rotate(0deg);
    }
  }
}
