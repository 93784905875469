@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-right: 5px;
  }
  ::before {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}

.name {
  @extend .bodyS;
  font-weight: bold;
  color: $color-dark-75;
  min-width: 128px;
  display: flex;
  align-items: center;

  .link {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.typeSymbol {
  max-width: 30px;
  background-color: $color-dark-25;
  padding: 6px;
  margin: auto;
  font-size: 12px;
  font-weight: bold;
  color: $color-white-100;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: $color-white-100;
  }
}

.previewColumn {
  display: flex;
  align-items: center;

  .link {
    margin-right: 8px;
  }

  .previewContainer {
    width: 40px;
    height: 40px;
    background: $color-dark-10;
    border-radius: 4px;
    margin-right: 8px;
    overflow: hidden;

    display: flex;
    align-items: center;
    flex: 0 0 auto;

    .preview {
      width: 100%;
    }
    .bannerPreview {
      cursor: pointer;
      width: 100%;
    }
  }

  .titleContainer {
    .title {
      @extend .bodyS;
      font-weight: bold;
      color: $color-dark-75;
    }

    .dimensions {
      @extend .caption;
      color: $color-dark-50;
    }
  }
}

.pause {
  ::before {
    background: $color-yellow-100;
  }
}
.active {
  ::before {
    background: $color-mantis-100;
  }
}
.closed {
  ::before {
    background: $color-red-100;
  }
}

.table-cell-right {
  text-align: right;
}

// hack to hide sorter for grouped column needed if column uses default sorting

.hideSorter > div > span.ant-table-column-sorter {
  display: none;
}

.hideSorter > div,
.hideSorter > div > span {
  width: 100%;
}

.ant-table-column-sorters {
  padding: 16px 8px;
}

.ant-table-tbody {
  .ant-table-cell {
    padding: 16px 8px;
  }
}

.ant-table-thead {
  tr:first-child {
    th:first-child {
      border-radius: 8px 0 0 8px !important;
    }
    th:last-child {
      border-radius: 0 8px 8px 0 !important;
    }
  }
  tr:nth-child(2) {
    th:nth-child(1),
    th:nth-child(2) {
      background-color: $color-white-100;
    }
    th:nth-child(3) {
      border-radius: 8px 0 0 8px !important;
    }
    th:last-child {
      border-radius: 0 8px 8px 0 !important;
    }
  }
}

.ant-table-column-has-sorters {
  padding: 0 !important;
}

.ant-table-thead > tr > th::before {
  display: none !important;
}

.campaign-table {
  .ant-table-thead {
    tr:nth-child(2) {
      th {
        background-color: $color-white-100;
      }
      th:nth-last-child(-n + 11) {
        background-color: $color-dark-5;
      }
      th:nth-last-child(11) {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }
}
.product-table {
  .ant-table-thead {
    tr:nth-child(2) {
      th {
        background-color: $color-white-100;
      }
      th:nth-last-child(-n + 9) {
        background-color: $color-dark-5;
      }
      th:nth-last-child(9) {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }
}

.ant-pagination {
  padding: 0 8px;
  background-color: $color-dark-5;
  height: 56px;
  display: flex;
  align-items: center;

  .ant-pagination-item,
  .ant-pagination-item-link {
    border: none;
    border-radius: 8px;
  }

  .ant-pagination-item-link {
    background-color: $color-dark-10;
  }

  .ant-select-selector {
    padding: 4px 16px !important;
    height: 33px !important;
  }

  .ant-pagination-prev {
    display: flex;
    order: 1000;
  }

  .ant-pagination-next {
    display: flex;
    order: 1001;
  }

  .ant-pagination-options {
    display: flex;
    order: -1;
    margin-right: 16px;

    //&:before {
    //  content: "Show rows: ";
    //  display: flex;
    //  align-items: center;
    //  margin-right: 8px;
    //  margin-top: 4px;
    //}
  }
}
