.searchContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 48px;
  padding-bottom: 24px;

  .searchInput {
    width: 100%;
    max-width: 370px;
    min-width: 170px;
    width: 370px;
  }
}
