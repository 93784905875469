@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.costs {
  min-height: 40px;
  margin-top: 18px;
  .costsSum {
    @extend .head2;
    color: $color-dark-100;
  }
}

.image {
  max-width: 100%;
}

.info {
  font-size: 12px;
  line-height: 16px;
  color: $color-dark-75;
  margin-top: 2px;
}
