@import './colors';
@import './fonts';

body {
  color: $color-dark-100;
  font-weight: 400;
  font-size: 14px;
  font-family: 'MainFont', sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0;
}

.head1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: -2px;
}

.head2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1.25px;
}

.head3 {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -1px;
}

.head4 {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.bodyL {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.bodyS {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.buttonL {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.buttonS {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

a {
  color: var($theme-primary-100) !important;
  :hover {
    color: var($theme-primary-75) !important;
  }
}
