@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.container {
  .ruleContainer {
    display: flex;
    margin-bottom: 8px;

    > :not(:last-child) {
      margin-right: 8px;
    }

    .remove {
      flex: 1 0 auto;
    }
  }

  .add {
    width: 100%;
  }
}
