@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $color-white-100;
  z-index: 100;

  display: flex;

  .infoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 418px;
    width: 418px;
    height: 100%;
    background-color: var($theme-primary-100);

    padding: 46px 24px 48px 24px;

    .title {
      @extend .head3;
      color: $color-white-100;
      margin-bottom: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fileInfo {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @extend .bodyS;
        color: $color-white-100;

        padding: 8px;
        box-shadow: 0 1px 0 $color-white-10;

        > :first-child {
          margin-right: 24px;
        }
      }
    }

    .bannerUrl {
      .title {
        display: flex;
        align-items: center;
        @extend .head4;
        color: $color-white-100;
        margin-bottom: 8px;
      }

      .icon {
        width: 16px;
        height: 16px;
        fill: $color-white-75;
        margin-left: 4px;
        cursor: pointer;
      }

      .subtitle {
        @extend .caption;
        color: $color-white-75;
        margin-bottom: 24px;
      }

      .info {
        @extend .caption;
        color: $color-white-75;
      }

      .input {
        opacity: 0.5;
        margin-bottom: 32px;
      }
    }

    .uppercase {
      text-transform: uppercase;
    }
  }

  .previewColumn {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    .corners {
      position: relative;
      padding: 15px;

      .corner1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-right: 1px solid $color-dark-100;
        border-bottom: 1px solid $color-dark-100;
      }
      .corner2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        border-left: 1px solid $color-dark-100;
        border-bottom: 1px solid $color-dark-100;
      }
      .corner3 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-right: 1px solid $color-dark-100;
        border-top: 1px solid $color-dark-100;
      }
      .corner4 {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 16px;
        height: 16px;
        border-left: 1px solid $color-dark-100;
        border-top: 1px solid $color-dark-100;
      }

      .imageContainer {
        padding: 8px;
        border: 1px dashed $color-dark-25;
        max-width: 100%;

        img {
          max-width: 100%;
        }
      }
    }

    .close {
      z-index: 1;
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}
