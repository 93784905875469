@import 'sass/_colors.scss';

.ant-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: var($theme-primary-100);
    }
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border-color: $color-dark-10;
    &:after {
      left: 8px;
    }
  }
}

.ant-checkbox-checked {
  &:after {
    border-radius: 8px;
    border: transparent !important;
  }

  &:hover {
    .ant-checkbox-inner {
      background: linear-gradient(0deg, $color-dark-25, $color-dark-25), var($theme-primary-100);
    }
  }
  .ant-checkbox-inner {
    background-color: var($theme-primary-100);
    border: transparent !important;
  }
}
