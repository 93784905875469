@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.row {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.breadcrumbContainer {
  display: flex;
  align-items: center;

  .homeIcon {
    margin-bottom: 2px;
    margin-right: 8px;
  }

  .breadcrumbItemMain {
    @extend .head3;
    color: $color-dark-100;
  }
  .breadcrumbItem {
    @extend .head3;
    font-weight: normal;
    color: $color-dark-50;
    transition: 0.2s ease all;
    &:hover {
      color: $color-dark-75;
    }
  }
  .separator {
    @extend .head3;
    font-weight: normal;
    color: $color-dark-50;
  }
}
.rangeContainer {
  display: flex;
  justify-content: flex-end;
}
