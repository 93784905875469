@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.itemForm {
  display: flex;
  align-items: center;
  .itemInput {
    flex: 1 0 auto;
    margin-right: 8px;
  }
}

.invalidItems {
  @extend .caption;
  color: $color-red-100;
  margin-top: 8px;
}

.exampleContainer {
  font-size: 12px;
  line-height: 16px;
  margin-top: 26px;
  color: $color-dark-75;
}
