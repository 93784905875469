$color-dark-100: #001b3a;
$color-dark-75: rgba(0, 27, 58, 0.75);
$color-dark-50: rgba(0, 27, 58, 0.5);
$color-dark-25: rgba(0, 27, 58, 0.25);
$color-dark-10: rgba(0, 27, 58, 0.1);
$color-dark-5: rgba(0, 27, 58, 0.05);

$color-white-100: #ffffff;
$color-white-75: rgba(255, 255, 255, 0.75);
$color-white-10: rgba(255, 255, 255, 0.1);

$color-blue-100: #479cff;
$color-blue-75: rgba(71, 156, 255, 0.75);
$color-blue-50: rgba(71, 156, 255, 0.5);
$color-blue-25: rgba(71, 156, 255, 0.25);
$color-blue-10: rgba(71, 156, 255, 0.1);

$color-green-100: #4DA057;
$color-green-75: rgba(77, 160, 87, 0.75);
$color-green-50: rgba(77, 160, 87, 0.5);
$color-green-25: rgba(77, 160, 87, 0.25);
$color-green-10: rgba(77, 160, 87, 0.1);

$color-red-100: #ff5660;
$color-red-10: rgba(255, 86, 96, 0.1);

$color-notice-100: #F4364C;

$color-yellow-100: #ffc56d;
$color-mantis-100: #63c561;

$theme-primary-100: --theme-primary-100;
$theme-primary-75: --theme-primary-75;
$theme-primary-50: --theme-primary-50;
$theme-primary-25: --theme-primary-25;
$theme-primary-10: --theme-primary-10;
