@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 764px;
  margin: auto;
  padding-top: 40px;

  .iconContainer {
    width: 48px;
    height: 48px;
    border: 1px solid $color-mantis-100;
    border-radius: 32px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      fill: $color-mantis-100;
    }
  }

  .successTitle {
    @extend .head3;
    text-align: center;
    color: $color-dark-100;
    margin-bottom: 24px;
  }

  .campaignTitle {
    color: var($theme-primary-100);
  }

  .successSubtitle {
    @extend .bodyL;
    text-align: center;
    color: $color-dark-75;
  }
}
