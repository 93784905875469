@import-normalize;
@import 'typography';
@import 'colors';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

body {
  min-width: 300px;
  background: #ffffff;
  margin: 0;
  padding: 0;
}

$theme-background: --theme-background;

body.martailer {
  #{$theme-primary-100}: $color-blue-100;
  #{$theme-primary-75}: $color-blue-75;
  #{$theme-primary-50}: $color-blue-50;
  #{$theme-primary-25}: $color-blue-25;
  #{$theme-primary-10}: $color-blue-10;
  #{$theme-background}: url(../assets/icons/BgLogin.svg);
}

body.iki {
  #{$theme-primary-100}: $color-green-100;
  #{$theme-primary-75}: $color-green-75;
  #{$theme-primary-50}: $color-green-50;
  #{$theme-primary-25}: $color-green-25;
  #{$theme-primary-10}: $color-green-10;
  #{$theme-background}: url(../assets/icons/BgLogin.svg);
}