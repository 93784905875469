@import 'sass/_colors.scss';

.container {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
}

.itemContainer {
    border-color: var($theme-primary-10);
    border-style: groove;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px 16px;
    width: 49%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selectedContainer {
    border-color: var($theme-primary-100);
    border-style: groove;
    border-width: 4px;
    border-radius: 6px;
}

.unselectedContainer {
    border-color: var($theme-primary-10);
}

.header {
    font-weight: bold; 
    font-size: 14px;
}

.body {
    font-weight: normal; 
    font-size: 12p; 
    color: $color-dark-50; 
    padding-top: 10px
}

.productCount {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.productCountHeader {
    font-weight: bold; 
    font-size: 14px;
}

.productCountNumber {
    font-weight: normal; 
    font-size: 12p; 
    color: $color-dark-50; 
    padding-left: 10px;
}