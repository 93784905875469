@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.container {
  display: flex;

  background: $color-white-100;
  border: 1px solid $color-dark-10;
  border-radius: 24px;
  overflow: hidden;

  &.small {
    height: auto;
    max-height: 418px;
    margin-bottom: 16px;

    .allItems {
      border-right: 1px solid $color-dark-10;
    }

    .selectedItems {
      background-color: $color-white-100;
    }

    .header {
      padding: 16px;
    }
    .body {
      padding: 16px 0;
    }

    .selectableItem,
    .selectedItem {
      padding: 8px 16px;
    }
    .createItem {
      padding: 4px 16px;
    }
  }

  &.noSearch {
    height: auto;
    .body {
      padding: 16px 0;
      height: auto;
    }

    .selectableItem,
    .selectedItem {
      padding: 8px 16px;
    }
    .createItem {
      padding: 4px 16px;
    }
  }

  .allItems {
    width: 50%;
  }

  .selectedItems {
    width: 50%;
    background-color: $color-dark-5;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 32px;
    box-shadow: 0 1px 0 $color-dark-10;

    .title {
      @extend .bodyS;
      color: $color-dark-100;
      margin-right: 32px;
    }

    .actions {
      > :not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .body {
    padding: 32px 0;
    height: 338px;
    overflow: auto;
  }
}

.selectableItem {
  display: flex;
  align-items: center;
  padding: 8px 32px;

  @extend .bodyS;
  color: $color-dark-75;

  &.disabled {
    cursor: not-allowed;
    color: $color-dark-50;

    .checkbox {
      pointer-events: none;
    }
  }

  &.bold {
    font-weight: bold;
  }

  .checkbox {
    margin-right: 16px;
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.button {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 4px;
  transition: 0.4s ease all;
  cursor: pointer;
}

.icon {
  width: 16px;
  height: 16px;
}

.selectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;

  @extend .bodyS;
  color: $color-dark-100;

  .remove {
    @extend .button;
    background: $color-dark-5;
    margin-left: 16px;

    .icon {
      @extend .icon;
      fill: $color-dark-100;
    }

    &:hover {
      background: $color-dark-10;
    }
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.createItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 32px;

  min-height: 40px;

  .input {
    width: 100%;
    margin-right: 8px;
  }

  .actions {
    display: flex;

    .save {
      @extend .button;
      background: var($theme-primary-100);
      margin-right: 8px;
      .icon {
        @extend .icon;
        fill: $color-white-100;
      }
    }
    .remove {
      @extend .button;
      background: $color-dark-5;
      .icon {
        @extend .icon;
        fill: $color-dark-100;
      }
    }
  }
}
