@import 'sass/_colors.scss';

.float-label {
  position: relative;
}

.label {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 16px;
  right: 16px;
  top: 15px;
  transition: 0.2s ease all;
  color: $color-dark-50;

  &.error {
    color: $color-red-100;
  }
}

.label-float {
  top: 2px;
  padding-top: 4px;
  font-size: 10px;
  z-index: 3;
  background-color: white;
}
