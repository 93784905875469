@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.retailProductItem {
  background-color: $color-dark-5;
  padding: 32px;
  border-radius: 24px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 32px;
  }

  &.edit {
    padding: 16px;
  }

  .productForm {
    margin-bottom: 0;
  }

  .productLinkForm {
    margin-bottom: 16px;
    min-height: 40px;
    > :first-child {
      justify-content: center;
    }
  }

  .linkContainer {
    display: flex;
    align-items: center;

    .toggle {
      margin-right: 8px;
    }

    .link {
      @extend .bodyS;
      color: $color-dark-75;
      background: $color-white-100;
      border-radius: 8px;
      padding: 10px 18px;

      display: flex;
      align-items: center;
      height: 40px;

      width: 100%;
      overflow: auto;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        cursor: pointer;
        fill: var($theme-primary-100);
        margin-right: 8px;
        min-width: 14px;
      }
    }
    .remove {
      flex: 1 0 auto;
      margin-left: 8px;
    }
  }

  .addContainer {
    display: flex;
    justify-content: flex-end;
  
    // margin: 8px 0;
  }

}
