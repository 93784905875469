@import 'sass/_typography.scss';
@import 'sass/_colors.scss';

.supportedSizes {
  width: 33.33%;
  margin-bottom: 32px;

  .title {
    display: flex;
    align-items: center;
    color: var($theme-primary-100);
    cursor: pointer;

    &:hover {
      color: var($theme-primary-75);
      .icon {
        fill: var($theme-primary-75);
      }
    }

    @extend .caption;
    margin-bottom: 8px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: var($theme-primary-100);
    }
  }

  .description {
    @extend .caption;
    color: $color-dark-50;
  }
}

.sizesDialogContainer {
  .title {
    @extend .head3;
    color: $color-dark-100;
    margin-bottom: 16px;
  }

  .subtitle {
    @extend .head4;
    color: $color-dark-100;
    margin-bottom: 8px;
  }

  .type {
    margin-top: 16px;
    margin-bottom: 32px;

    .itemRow {
      display: flex;
      padding: 8px 0;
      box-shadow: 0 1px 0 rgba(0, 27, 58, 0.1);
    }

    .itemCol {
      @extend .bodyS;
      color: $color-dark-75;
      width: 50%;
    }
  }

  .dimensions {
    margin-top: 16px;
    .itemCol {
      width: 100%;
      margin-top: 16px;
      .item {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        box-shadow: 0 1px 0 rgba(0, 27, 58, 0.1);
      }
    }
  }
}
